import { BasisPricingLocation } from '../../../_modules/zen-rate-checks/_model/rate-check-v3.model';
import {ContractStatusV2} from '../_models/energyplan-v2';

// all the friendly status strings required for the advisor's contract list page,
// customer's contract list page and customer's detail page
export enum ContractFriendlyStatus {
  Processing = 'Processing',
  Customer_Review = 'Customer Review',
  Bidding = 'Bidding',
  Contract_Review = 'Contract Review',
  Contract_Generation = 'Contract Generation',
  Supplier_Review = 'Supplier Review',
  No_Longer_In_Process = 'No Longer In Process',
  Aborted = 'Aborted',
  Declined = 'Declined',
  Timed_Out = 'Timed Out',
  Expired = 'Expired',
  Draft = 'Draft',
  Rejected = 'Rejected',
  Future = 'Future',
  Current = 'Current',
  Default_Service = 'Default Service',
  Booked_Contract = 'Booked Contract',
  On_Utility = 'On Utility',
  Contract_Requested = 'Contract Requested', // isMatrix
}
export enum MLOAAuthorizationFriendlyStatusDescription {
  eSigned = 'E-signed',
  SentToday = 'Sent today, not signed',
  SentBeforeToday = 'Sent but expired, needs to be resent',
  NotSent = 'Not yet sent'
}
export enum RequestStatusDescription {
  Requested = 'Request in process',
  Request = 'Request',
  AuthRequired = 'Authorization required'
}
export enum ContractFriendlyStatusDescription {
  Current = 'In a flowing contract with a 3rd party supplier.',
  Future = 'In a future contract with a third party supplier.',
  Processing = 'Currently pending in another Rate Check flow.',
  On_Utility = 'Taking service from the local utility.',
  Expired = 'Previously in a contract with a 3rd party supplier that has expired or is expiring in the current month.'
}

export function friendlyContractStatusMap(contractStatusV2: ContractStatusV2): ContractFriendlyStatus {

  if (contractStatusV2.inProcessStatusDetail) {
    return Object.values(ContractFriendlyStatus).find(v => v === contractStatusV2.inProcessStatusDetail);
  }

  if (contractStatusV2.negativeStatusDetail) {
    return Object.values(ContractFriendlyStatus).find(v => v === contractStatusV2.negativeStatusDetail);
  }
   return Object.values(ContractFriendlyStatus).find(v => v === contractStatusV2.contractStatus);
}


export function friendlyContractStatusNoLongerInProcessMap(contractStatusV2: ContractStatusV2) {
  if (contractStatusV2.inProcessStatusDetail) {
    return contractStatusV2.contractStatus;
  }

  if (contractStatusV2.negativeStatusDetail) {
    return contractStatusV2.contractStatus;
  }

  // If it is not processing, and not negative, it is booked, for grouping purposes.
  return 'Booked Contract';
}

export function setContractStatusClsString(contractStatus: ContractFriendlyStatus) {
  switch (contractStatus) {
    case ContractFriendlyStatus.No_Longer_In_Process:
    case ContractFriendlyStatus.Aborted:
    case ContractFriendlyStatus.Declined:
    case ContractFriendlyStatus.Timed_Out:
    case ContractFriendlyStatus.Rejected:
      return 'status-mark-expired';
    case ContractFriendlyStatus.Expired:
      return 'status-mark-initial';
    case ContractFriendlyStatus.Future:
      return 'status-mark-future';
    case ContractFriendlyStatus.Current:
      return 'status-mark-active';
    case ContractFriendlyStatus.Default_Service:
      return 'status-mark-default';
    default:
     return 'status-mark-pending';
  }
}

export function basisPricingLocationToString(basisPricingLocation: BasisPricingLocation) {
  switch (basisPricingLocation) {
    case BasisPricingLocation.BURNER_TIP:
      return 'Burner Tip';
    case BasisPricingLocation.CITY_GATE:
      return 'City Gate';
    default:
      return basisPricingLocation;
  }
}
