import {EventEmitter, Injectable} from '@angular/core';
import {ZenMatTableSelectOption, ZenMatTableSelectSearchConfig} from '../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {MatTableDefaultSearchField} from '../../../_shared/_enums/zen-mat-table.enum';
import {CommodityType} from '../../../_shared/_zen-legacy-common/_models/commodity';
import {SelectionModel} from '@angular/cdk/collections';
import {
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../_shared/_enums/zen-dialogs.enum';
import {ZenContractTblRowModel, ZenContractV4ListModel} from '../../../_shared/_model/contract-v4.model';
import {ZenEditContractDialogComponent} from '../../../_shared/_dialogs/zen-edit-contract-dialog/zen-edit-contract-dialog.component';
import {ZenDialogMsgService} from '../../../_shared/_services/zen-dialog-msg.service';
import {MatDialog} from '@angular/material/dialog';
import {ContractSource, ContractSourceFriendlyText, ContractStatus} from '../../../_shared/_enums/zen-contract.enum';
import {ContractsV4Service} from '../../../_shared/_services/v4/contracts-v4.service';
import {ZenErrorMsgEnum} from '../../../_shared/_enums/zen-error-msg.enum';
import {ContractFriendlyStatus, RequestStatusDescription} from '../../../_shared/_zen-legacy-common/_utils/contract-utils';
import {capitalizeEachFirstLetter, capitalizeFirstLetter} from '../../../_shared/_zen-legacy-common/_utils/format-utils';
import moment from 'moment';
import {ZenPageEnum} from '../../../_shared/_enums/zen-page.enum';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {ZenIconsEnum} from '../../../_shared/_enums/zen-icons.enum';
import {RateType} from '../../../_shared/_zen-legacy-common/_models/energyplan-v2';
import {PortfolioHierarchyLevelEnum} from '../../portfolio/_enums/portfolio-hierarchy-level.enum';
import {ZenNumberFormat} from '../../../_shared/_enums/zen-number-format.enum';
import {UntypedFormControl} from '@angular/forms';
import {InitialFiltersService} from '../../portfolio/_services/_helpers/initial-filters.service';
import {TemporaryStorageService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/common/temporary-storage.service';
import {Router} from '@angular/router';
import {
  OrganizationManagementService
} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/organization-management.service';
import {RateCheckStatusEnum} from '../../../_shared/_zen-legacy-common/_models/rate-checks/rate-check-status';
import {ZenMatTableHelperService} from '../../../_shared/_services/helpers/zen-mat-table-helper.service';
import {ZenBreadcrumbService} from '../../../_shared/_services/zen-breadcrumb.service';
import {orderBy} from '../../../_shared/_zen-legacy-common/_utils/orderby.utils';
import {
  DocusignContractsService
} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/docusign/docusign-contracts.service';
import {AuthenticationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {NavigationService} from '../../../_shared/_zen-legacy-common/zen-common-services/_services/navigation.service';
import {map} from 'rxjs/operators';
import {PageableModel} from '../../portfolio/_model/pageable.model';
import {sanitizeFilename} from '../../../_shared/_utils/zen-filename.util';
import {CustomerServiceV4} from '../../../_shared/_services/v4/customer-v4.service';
import {ZenUnitsEnum} from '../../../_shared/_enums/zen-units.enum';
import {ContractFilterService} from '../../portfolio/_services/_helpers/contract-filter.service';
import {PortfolioFilterService} from '../../portfolio/_services/_helpers/portfolio-filter.service';
import {CommodityFeeFormatPipe} from '../../../_shared/_pipes/commodity-fee-format.pipe';
import {CustomerIdSessionService} from '../../../_shared/_services/customer-id/customer-id-session.service';
import {MloaDocumentDetailsDTO} from '../../portfolio/_model/portfolio-customers.model';
import {getContractStatusCls} from '../../../_shared/_utils/zen-contract.util';
import {TranslateService} from '@ngx-translate/core';
import {ZenCurrencyTypeEnum} from '../../../_shared/_enums/zen-currency-type.enum';
import {CountryEnum, ZenLocaleModel} from '../../../_shared/_model/zen-locale.model';
import {ZenUnitsHelperService} from '../../../_shared/_services/helpers/zen-units-helper.service';
import {UnitService} from '../../../_shared/_services/v4/unit-service';
import {UnitDto} from '../../../_shared/_model/unit-model';
import {element} from 'protractor';


export const ContractEditAllowedStatues =
  [ContractFriendlyStatus.Current,
    ContractFriendlyStatus.Future,
    ContractFriendlyStatus.Expired,
    ContractFriendlyStatus.Draft];

@Injectable({
  providedIn: 'root'
})
export class ZenContractListHelperService {
  electricitySelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
  gasSelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
  draftSelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
  contractSelectedTab = CommodityType.Electricity;

  services = [
    {name: 'Electricity', value: CommodityType.Electricity},
    {name: 'Gas', value: CommodityType.Gas},
  ];

  contractTypes = [
    {name: 'Fixed', value: 'fixed'},
    {name: 'Variable', value: 'variable'}
  ];

  contractSources = [
    {name: ContractSourceFriendlyText.STANDARD_MIX, value: ContractSource.ELECTRIC_STANDARD_MIX},
    {name: ContractSourceFriendlyText.RENEWABLE_E_CERTIFIED, value: ContractSource.ELECTRIC_RENEWABLE_E_CERTIFIED},
    {name: ContractSourceFriendlyText.RENEWABLE_ELECTRIC, value: ContractSource.ELECTRIC_RENEWABLE},
  ];

  contractConfigs: { name: string; value: number }[] = [];

  arrRowPopperData: string;

  platformRatePopperData: string;

  mloaRowPopperData: string;
  mloaRowData: ZenContractTblRowModel;
  // Contract table select filter config
  selectSearchConfigElec: ZenMatTableSelectSearchConfig = {
    selectOptions: [],
    searchTypeCtrl: new UntypedFormControl(null),
    inputCtrl: new UntypedFormControl(null),
    onChange: () => {
    }
  };
  selectSearchConfigGas: ZenMatTableSelectSearchConfig = {
    selectOptions: [],
    searchTypeCtrl: new UntypedFormControl(null),
    inputCtrl: new UntypedFormControl(null),
    onChange: () => {
    }
  };
  draftSearchConfig: ZenMatTableSelectSearchConfig = {
    selectOptions: [],
    searchTypeCtrl: new UntypedFormControl(null),
    inputCtrl: new UntypedFormControl(null),
    onChange: () => {
    }
  };
  onContractDetailsChanged: EventEmitter<ZenContractTblRowModel> = new EventEmitter<ZenContractTblRowModel>();
  show = {
    electricityTable: true,
    natGasTable: true,
    draftTable: true
  }
  // Will only be set if the user is a customer
  private customerCompanyName: string;

  showPageLoader: boolean;
  isHierarchyLevel: boolean;
  translations: ZenLocaleModel;
  allUnits: UnitDto[];
  constructor(private zenDialogSvc: ZenDialogMsgService,
              private contractFilterSvc: ContractFilterService,
              private pfFilterSvc: PortfolioFilterService,
              private dialog: MatDialog,
              private pfFiltersSvc: InitialFiltersService,
              private tempStorageSvc: TemporaryStorageService,
              private contractsV4Svc: ContractsV4Service,
              private decimalPipe: DecimalPipe,
              private currencyPipe: CurrencyPipe,
              protected router: Router,
              private zenUnitHelper: ZenUnitsHelperService,
              private unitService: UnitService,
              private orgSvc: OrganizationManagementService, protected zenMatTableHelperSvc: ZenMatTableHelperService,
              private bcSvc: ZenBreadcrumbService, private docusignService: DocusignContractsService,
              protected authSvc: AuthenticationService, private navSvc: NavigationService,
              private customerSvc: CustomerServiceV4,
              public translateSvc: TranslateService,
              private feeFormat: CommodityFeeFormatPipe,
              private customerIdSvc: CustomerIdSessionService) {
    this.translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;
    this.unitService.getUnits().subscribe(units => {
      this.allUnits = units;
    });
    // If hide ARR changes, reconfig and refresh table
    this.orgSvc.onHideArrChangeEmit.subscribe(() => {
      this.refreshElectricityTable();
      this.refreshNatGasTable();
      this.refreshDraftTable();
    });
  }

  getCustomerDetailsIfUserCustomer() {
    if (this.authSvc.isCustomer() && !this.customerCompanyName) {
      this.customerSvc.getCustomerDetails(this.authSvc.getCurrentCustomerId()).subscribe((customer) => {
        this.customerCompanyName = customer.companyName;
      });
    }
  }

  getTableDownloadFileName(baseName: string): string {
    let fileName = baseName;
    let hierarchyBaseLevel = PortfolioHierarchyLevelEnum.PORTFOLIO;
    if (this.authSvc.isCustomer()) {
      hierarchyBaseLevel = PortfolioHierarchyLevelEnum.CUSTOMERS;
    }
    if (this.pfFilterSvc.hierarchyLevel > hierarchyBaseLevel) {
      // Gets the enum name in lower case.
      fileName = `${PortfolioHierarchyLevelEnum[this.pfFilterSvc.hierarchyLevel].toLowerCase()}_` + fileName;
    } else if (this.authSvc.isCustomer()) {
      fileName = `${this.customerCompanyName}_` + fileName;
    } else {
      // Put org in front
      fileName = `${this.orgSvc.organization.companyName.toLowerCase().trim()}_` + fileName;
    }
    return fileName;
  }

  canBeRebooked(selectedRow: ZenContractTblRowModel): boolean {
    if (selectedRow) {
      const {activeMeterCount, rateCheckStatusCode, planEndDate} = selectedRow;
      return  planEndDate != null &&  activeMeterCount > 0 && (rateCheckStatusCode === null || rateCheckStatusCode === RateCheckStatusEnum.COMPLETE);
    }
  }


  handleContractDelete(rowData: ZenContractTblRowModel) {
    this.zenDialogSvc.showConfirmationDialog(
      'Portfolio Management',
      `Are you sure you want to delete this contract with ${rowData?.supplierName}?`,
      null,
      () => {
        this.showPageLoader = true;
        this.contractsV4Svc.deleteContractById(rowData.customerId, rowData.energyPlanId).subscribe(() => {
          if (rowData.contractStatus === ContractFriendlyStatus.Draft) {
            this.refreshDraftTable()
          } else if (rowData.commodityType === CommodityType.Electricity) {
            this.refreshElectricityTable();
          } else if (rowData.commodityType === CommodityType.Gas) {
            this.refreshNatGasTable();
          }
          this.showPageLoader = false;
          setTimeout(() => this.zenDialogSvc.openToast(true, 'Contract deleted successfully.'), 100);
        }, e => {
          this.showPageLoader = false;
          this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        });
      });
  }

  handleViewContract(rowData: ZenContractTblRowModel, openEditOnView = false) {
    // portfolioPage = coming from a page from portfolio tab, e.g. serviceAddress, meter. contractsPage = coming from contracts list tab.
    let fromPage = this.router.url.includes('portfolio') ? 'portfolioPage' : 'contractsPage';
    let fromId = null;

    // Neat trick to get the "come from" title - use the breadcrumbs; only for portfolio pages.
    if (this.pfFilterSvc.hierarchyIds.customerId
      || this.pfFilterSvc.hierarchyIds.serviceAddressId
      || this.pfFilterSvc.hierarchyIds.lenId
      || this.pfFilterSvc.hierarchyIds.meterId) {
      if (this.bcSvc.config?.breadcrumbs) {
        fromId = this.bcSvc.config.breadcrumbs[this.bcSvc.config.breadcrumbs.length - 1].title;
      } else if (this.bcSvc.config?.mobileBreadcrumbs) {
        fromId = this.bcSvc.config.mobileBreadcrumbs[this.bcSvc.config.mobileBreadcrumbs.length - 1].title;
      }
    }
    this.customerIdSvc.setCustomerIdFromParams(rowData.customerId);
    const extras = {queryParams: {fromURL: this.router.url, fromId: fromId, fromPage: fromPage, openEdit: openEditOnView}};
    this.router.navigate(this.navSvc.getContractDetailsPageRoute(rowData.customerId, rowData.energyPlanId), extras);
  }

  handleContractDownload(rowData: ZenContractTblRowModel) {
    const fileName = this.getContractDocFileName(rowData);
    if (rowData.documentId && !rowData.isMatrixPricing) {
      this.tempStorageSvc.downloadDocument(rowData.customerId, rowData.documentId, fileName)
        .subscribe(() => {
        }, error => {
          console.log('Error: download contract ', error);
          this.zenDialogSvc.openToast(false);
        });
    } else {
      // Lets show a matrix downloaded specific dialog
      let dialogRef;
      let dialogData: ZenDialogDataModel = {
        type: ZenDialogDataType.MATRIX_DOWNLOADER, // Couldn't handle this scenario by passing in data, so I created a custom enum
        header: {title: ''},
        bodyHtml: '',
        onClose: () => dialogRef.close(),
        actionButtons: [],
      };

      this.dialog.open(ZenDialogComponent, {
        width: ZenDialogSizeEnum.SMALL,
        data: dialogData, autoFocus: false,
        disableClose: true // this is so they can't close it out by themselves, but it will close when API finishes(error or success)
      });


      // If there is no document id, check to see if its a valid matrix contract scenario
      if (rowData.isMatrixPricing && rowData.rateCheckStatusCode && rowData.rateCheckStatusCode >= 505) {
        this.docusignService.getEnvelopeRequestWithCustomerId(rowData.rateCheckId, rowData.customerId)
          .subscribe(response => {
            if (response.envelopeId) {
              const baseUrl = `/v2/docusign/customer/${response.customerId}/envelopes/${response.envelopeId}/`;
              const apiUrl = response.countersignedUploaded ? baseUrl + `countersigned/document` : baseUrl + 'document';
              this.docusignService.downloadDocument(apiUrl, fileName).then(() => {
                this.dialog.closeAll();
              })
            }
          }, e => {
            console.log('Error getEnvelopeRequest : ', e);
            this.dialog.closeAll();
          })
      } else {
        console.log('No documentId');
      }
    }
  }

  /** Details page hierarchy level API calls also handled here - for (Org level and for details pages), using
   * hierarchyLevelFilterIds(): PortfolioFilterResourceIdRequest return responses. */
  getDataObservable(commodityType: CommodityType | null, page = ZenPageEnum.DEFAULT_PAGE, size = ZenPageEnum.DEFAULT_MIN_SIZE, isActive?: boolean, sortBy?: string, sortDir?: string, customerId?: number, lenId?: string, serviceAddressId?: number, meterId?: number, isDraft?: boolean) {
    // Setting EP filters.
    let energyPlanFilters = {}; // {customerName: 'comp 1'} or {legalEntityName: 'Len A'}

    const selectedOption = this.chooseSelectSearchConfig(commodityType, isDraft).searchTypeCtrl.value as ZenMatTableSelectOption;
    const inputVal = this.chooseSelectSearchConfig(commodityType, isDraft).inputCtrl.value;

    if (selectedOption || inputVal) {
      energyPlanFilters[selectedOption?.objectName] = this.zenMatTableHelperSvc.setInputDataTypeConvert(inputVal, selectedOption.dataType);
    }


    let energyPlanFilterString: any = isDraft ? { energyPlanFilters } : { commodityTypes: [commodityType], energyPlanFilters };

    if (isActive === false) {
      energyPlanFilterString['activationStatuses'] = [false];
    }

    let filtersObj = JSON.parse(this.contractFilterSvc.getFilterString(customerId, lenId, serviceAddressId, meterId));
    let filterString = JSON.stringify({ ...filtersObj, ...energyPlanFilterString });

    return this.contractsV4Svc.getContracts(filterString, page, size, sortBy, sortDir, isDraft).pipe(
      map(contracts => {
        let tblRows: ZenContractTblRowModel[] = contracts.content
          .filter((v, i, a) => a.findIndex(v2 => (v2.energyPlanId === v.energyPlanId)) === i)
          .map(c => {
            return this.getContractRowModified(c);
          });
        let tblPageable: PageableModel<ZenContractTblRowModel> = { ...contracts, content: tblRows }; // overwrite table rows
        return tblPageable;
      })
    );
  }

  chooseSelectSearchConfig(commodityType: CommodityType, isDraft: boolean) {
    if (isDraft) {
      return this.draftSearchConfig;
    }

    switch (commodityType) {
      case CommodityType.Electricity:
        return this.selectSearchConfigElec;
      case CommodityType.Gas:
        return this.selectSearchConfigGas;
    }
  }

  getContractRowModified(c: ZenContractV4ListModel): ZenContractTblRowModel {
    const _lens = c.lens;
    const _lenNames = _lens?.map(l => l.name);
    const {statusName, statusCls, subSubText} = this.getReqStatusDetails(c);
    const supplierNameGreenIcon  = `<i class="material-icons color-icon contracted no-bg p-0 vertical-align-bottom">${ZenIconsEnum.GREEN}</i>  `;

    const isCanada = this.translateSvc.getDefaultLang() === CountryEnum.CA;

    return {
      ...c,
      annualUsage: isCanada ? c.annualUsageNative : c.annualUsage,
      primaryLen: (_lens?.length > 0 ? _lens[0]?.name : '-'), // Grab first LEN
      lenNames: (_lens?.length > 0 ? _lenNames.slice(this.isHierarchyLevel ? 1 : 0) : []), // and set the rest, ONLY IF CUSTOMER VIEW
      lens: _lens,
      statusCls: getContractStatusCls(c.contractStatus),
      contractStrategy: this.getContractStrategyText(c),
      contractPlatformStrategy: this.getNativeRateText(c),
      contractStrategyProcurement: this.getContractStrategyProcurementText(c), // Used in procurement snapshot dialog
      rateTypeText: capitalizeFirstLetter(c.rateType),
      contractConfiguration: c.productModelConfiguration?.productModel?.name || '-',
      supplierName: `${c.supplierName} ${c.greenPercent > 0 ? supplierNameGreenIcon : ''}`,
      isNonPlatformUnit: (c.unitId !== 1 && c.unitId !== 3),
      bookedInOurSystemIcon: (c.rateCheckId && c.rateCheckStatusCode === RateCheckStatusEnum.COMPLETE ? ZenIconsEnum.VERIFIED : null),
      bookedInOurSystemCls: 'material-symbols-rounded no-bg',
      startDateText: c.planStartDate == null ? '-' : this.dateFormatter(c.planStartDate), // To support client side search
      endDateText: c.planEndDate == null ? '-' :  this.dateFormatter(c.planEndDate), // To support client side search
      usageSubText: isCanada ? '' : (c.annualUsageNative !== c.annualUsage ?  this.numberFormatter(c.annualUsageNative) + ' ' +  c.unit : ''),
      // Linked contracts -> Lock condition
      lockedContract: Boolean(c.rateCheckId && c.rateCheckStatusCode === RateCheckStatusEnum.COMPLETE),
      // Contract download column icon
      downloadIcon: c.documentId ? 'download' : '',
      downloadIconCls: c.documentId ? 'material-symbols-rounded' : '',
      // Draft Contract data
      renewableIcon: c?.greenPercent > 0 ? `<i class="material-icons color-icon no-bg success-2-color p-0">${ZenIconsEnum.GREEN}</i>` : '',
      serviceType: c.commodityType === CommodityType.Electricity ? ZenIconsEnum.ELECTRIC : ZenIconsEnum.NAT_GAS,
      serviceTypeCls: c.commodityType === CommodityType.Electricity ? 'electric' : 'nat-gas',
      draftContractStrategy: `<div class="ms-1">${c.supplierName}<p class="text-sm mt-2 pt-1">${this.getContractStrategyText(c).replace('0M ', '')}</p></div>`,
      // Authorization
      authorizationCls: c.mloaDocumentDetails != null ? this.getAuthorizationClass(c.mloaDocumentDetails) : '',
      authorizationStatus: c.planEndDate == null ? this.getAuthorizationStatus(c.mloaDocumentDetails) : '',
      authorizationSubText: this.getAuthorizationSubText(c.mloaDocumentDetails),
      // Request Status
      requestStatusCls: statusCls,
      requestStatusText: `<p class="text-md ${statusName === RequestStatusDescription.Request ? 'blue-1-color' : ''}">${statusName}</p>`,
      requestStatusSubText: subSubText
    } as ZenContractTblRowModel;
  }

  getReqStatusDetails(c: ZenContractV4ListModel): { statusName: string; statusCls: string; subSubText?: string; } {
    const {mloaDocumentDetails, request} = c || {};
    if (mloaDocumentDetails?.mloaSigned) {
      if (request) {
        return {statusName: RequestStatusDescription.Requested, statusCls: 'processing', subSubText: moment(request?.createDt).format('MMM DD, yyyy')};
      } else {
        return {statusName: RequestStatusDescription.Request, statusCls: 'inactive'};
      }
    } else {
      return { statusName: RequestStatusDescription.AuthRequired, statusCls: 'expired'};
    }
  }

  getAuthorizationClass(mloaDocumentDetails: MloaDocumentDetailsDTO): string {
    if (mloaDocumentDetails == null || (!mloaDocumentDetails.mloaSigned  && !mloaDocumentDetails.mloaSentDate)) {
      return 'inactive';
    }

    // Resend scenarios
    if (!mloaDocumentDetails.mloaSigned && mloaDocumentDetails.mloaSentDate != null) {
      const today = moment().startOf('day'); // Start of today for comparison
      const mloaSentDate = moment(mloaDocumentDetails.mloaSentDate);
      return mloaSentDate.isBefore(today) ? 'expired' : 'processing';
    }

    if (mloaDocumentDetails.mloaSigned) {
      return 'active';
    }

    return 'expired';
  }

  getAuthorizationSubText(mloaDocumentDetails: MloaDocumentDetailsDTO): string {
    if (!mloaDocumentDetails || !mloaDocumentDetails.mloaSentDate) {
      return '';
    }
    const today = moment();

    if (mloaDocumentDetails.mloaSignedDate) {
      const signDate = moment(mloaDocumentDetails.mloaSignedDate);
      if (signDate.isSame(today, 'day')) {
        return `Signed today @ ${signDate.format('h:mm a')}`;
      } else {
        return `Signed ${signDate.format('MMM D, YYYY')}`;
      }
    }

    if (mloaDocumentDetails.mloaSentDate) {
      const sentDate = moment(mloaDocumentDetails.mloaSentDate);

      if (sentDate.isSame(today, 'day')) {
        return `Sent today @ ${sentDate.format('h:mm a')}`;
      } else {
        return `Sent ${sentDate.format('MMM D, YYYY')}`;
      }
    }

    return '';
  }

  getAuthorizationStatus(mloaDocumentDetails: MloaDocumentDetailsDTO): string {
    if (mloaDocumentDetails == null || (!mloaDocumentDetails.mloaSigned  && !mloaDocumentDetails.mloaSentDate)) {
      return '<span class="blue-link">Send</span>';
    }

    if (!mloaDocumentDetails.mloaSigned && mloaDocumentDetails.mloaSentDate != null) {
      return '<span class="blue-link">Resend</span>';
    }

    if (mloaDocumentDetails.mloaSigned) {
      return '<span>E-signed</span>';
    }

    return '<span class="blue-link">Send</span>';
  }

  getContractStrategyText(c: ZenContractV4ListModel): string {
    return `${c.termMonths}M ${capitalizeFirstLetter(c.rateType)} @ ${this.feeFormat.transform(c.supplierRate || 0, {commodity: c.commodityType, currencyType: ZenCurrencyTypeEnum.CURRENCY})} / ${c.unit}`;
  }

  getNativeRateText(c: ZenContractV4ListModel): string {
    const platformRate =  this.zenUnitHelper.applyToPlatformMultiplier(c.unit, c.supplierRate, this.allUnits);

    return `Platform Default @ ${this.feeFormat.transform(platformRate || 0, {commodity: c.commodityType, currencyType: ZenCurrencyTypeEnum.CURRENCY})} / ${c.commodityType === CommodityType.Electricity ? ZenUnitsEnum.kWh : ZenUnitsEnum.Dth}`;
  }

  getContractStrategyProcurementText(c: ZenContractV4ListModel): string {
    return `${capitalizeFirstLetter(c.rateType)} @ ${this.feeFormat.transform(c.supplierRate || 0, {commodity: c.commodityType, currencyType: ZenCurrencyTypeEnum.CURRENCY})}`;
  }

  handleAddEditContracts(rowData?: ZenContractTblRowModel, lockedCustomerId?: number,
                         lockedCommodityType?: CommodityType, lockedState?: string, callback?: Function, serviceAddressId?: number, meterId?: number, uncheckDraft?: boolean) {
    this.resetSelectedRows();
    const _service = (lockedCommodityType || this.contractSelectedTab) === CommodityType.Electricity ? 'Electricity' : 'Gas';
    let dialogData: ZenDialogDataModel = {
      type: rowData?.energyPlanId ? ZenDialogDataType.EDIT_DIALOG : ZenDialogDataType.ADD_DIALOG,
      data: {...rowData, lockedCustomerId, lockedCommodityType, lockedState, serviceAddressId, meterId, uncheckDraft},
      header: {title: (rowData?.energyPlanId ? 'Edit' : 'Add') + ' ' + _service + ' Contract', icon: ZenIconsEnum.CONTRACTS_V2},
      bodyText: rowData && rowData.energyPlanId ? `Edit the information below to maintain an accurate record of this contract.`
        : `Add the required details below to create a new ${_service} supply contract.`,
      onClose: () => dialogRef.close(),
      actionButtons: [
        {label: 'Cancel', command: () => dialogRef.close()},
        {
          label: 'Save', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary', styleClass: 'ms-5',
          command: () => {
            dialogRef.close();
            this.zenDialogSvc.openToast(true);
          }
        }
      ]
    };

    const dialogRef = this.dialog.open(ZenEditContractDialogComponent, {
      width: ZenDialogSizeEnum.LARGE,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result: ZenContractV4ListModel) => {
      if (result) {
        if (result.planEndDate == null) {
          this.refreshDraftTable()
        } else if (result.commodityType === CommodityType.Electricity) {
          this.refreshElectricityTable();
        } else if (result.commodityType === CommodityType.Gas) {
          this.refreshNatGasTable();
        }
      }

      if (callback) {
        callback(result);
      }

      // If result != null, that means save button was hit
      this.onContractDetailsChanged.next(null);
    });
  }

  dateFormatter(_date, format = 'MMM YYYY') {
    if (_date == null) { return '-'; }

    return moment(_date).format(format);
  }

  currencyFormatter(val: number) {
    return `${this.currencyPipe.transform(val || 0, this.translations?.currency?.abbreviation, 'symbol-narrow')}`;
  }

  numberFormatter(num: number, format = ZenNumberFormat.USAGE) {
    return num ? `${this.decimalPipe.transform(num || 0, format)}` : '-';
  }

  numberFormatterWithUnit(row: ZenContractV4ListModel, format = ZenNumberFormat.USAGE) {
    return row.annualUsage ? `${this.decimalPipe.transform(row.annualUsage || 0, format)} ${row.commodityType === CommodityType.Electricity ? 'kWh' : 'Dth'}` : '-';
  }
  resetSelectedRows() {
    this.electricitySelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
    this.gasSelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
    this.draftSelectedRows = new SelectionModel<ZenContractTblRowModel>(true, []);
  }

  getConfigs(commodity: CommodityType, rateType: RateType, state: string) {
    if (rateType != null) {
      this.contractsV4Svc.getProductModels(commodity, rateType, state).subscribe(configs => {
        this.contractConfigs = configs.map(c => ({name: c.name, value: c.id}));
      }, e => {
        console.log('Error: Get product configs ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.zenDialogSvc.openToast(false);
      });
    }
  }

  /** Select search filters STARTS */
  getTableFilters() {
    this.pfFiltersSvc.getPortfolioTableFilters().subscribe((tblFilters) => {
      this.setSearchConfig(tblFilters.energyPlanFilters, this.chooseSelectSearchConfig(CommodityType.Electricity, false));
      this.setSearchConfig(tblFilters.energyPlanFilters, this.chooseSelectSearchConfig(CommodityType.Gas, false));
      this.setSearchConfig(tblFilters.energyPlanFilters, this.chooseSelectSearchConfig(CommodityType.Electricity, true))
    }, e => {
      console.log('Error: Get table filters ', e);
      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      this.zenDialogSvc.openToast(false);
    });
  }

  setSearchConfig(selectedFilters, config:  ZenMatTableSelectSearchConfig) {
    const keys = Object.keys(selectedFilters);
    if (keys?.length) {
      config.selectOptions = [];
      keys.forEach(key => {
        config.selectOptions.push({
          objectName: key,
          objectLabel: selectedFilters[key],
          dataType: this.zenMatTableHelperSvc.setSearchDataType(key)
        });
      });
      config.selectOptions = orderBy(config.selectOptions, 'objectLabel');
      // Setting default select option value
      config.searchTypeCtrl.setValue(config.selectOptions.find(op => op.objectName === MatTableDefaultSearchField.CONTRACT));
    }
  }

  /** Select search filters ENDS */

  getContractDocFileName(contract: ZenContractV4ListModel): string {
    let description = contract.productModelConfiguration?.productModel?.name ? contract.productModelConfiguration?.productModel?.name + '__' : '';
    let fileName = `${contract.supplierName}__${description}${moment(contract.planStartDate).format('MMM YYYY')}__${moment(contract.planEndDate).format('MMM YYYY')}`;
    return sanitizeFilename(fileName);
  }

  public refresh() {
    this.refreshElectricityTable();
    this.refreshNatGasTable();
    this.refreshDraftTable();
  }

  public refreshDraftTable() {
    setTimeout(() => this.show.draftTable = false);
    setTimeout(() => this.show.draftTable = true);
  }

  public refreshElectricityTable() {
    setTimeout(() => this.show.electricityTable = false);
    setTimeout(() => this.show.electricityTable = true);
  }

  public refreshNatGasTable() {
    setTimeout(() => this.show.natGasTable = false);
    setTimeout(() => this.show.natGasTable = true);
  }

  getContractTextFromListObj(c: ZenContractV4ListModel) {
    // @ts-ignore
    if (c?.contractStatus === ContractStatus.on_utility || c?.contractStatus === ContractFriendlyStatus.Default_Service) {
      return ContractStatus.on_utility;
    } else {
      const contractStart = moment(c?.planEndDate).subtract('months', c?.termMonths).format('MMM yyyy');
      return c?.supplierName && c?.planEndDate ?
        `${c.supplierName} | ${contractStart} - ${moment(c.planEndDate).format('MMM yyyy')}` : '-';
    }
  }

  getContractSubTextFromListObj(c: ZenContractV4ListModel) {
    // @ts-ignore
    if (c?.contractStatus === ContractStatus.on_utility || c?.contractStatus === ContractFriendlyStatus.Default_Service) {
      return this.getCommodityRate(c?.supplierRate, c.commodityType);
    } else {
      const _swing = c?.productModelConfiguration?.configuration?.swing;
      const swingText = _swing ? `${_swing}% Swing` : null;
      return this.getContractSubTextDetails(c, swingText);
    }
  }

  getContractSubTextDetails(c: ZenContractV4ListModel, swing: string | null) {
    const details = [
      `${c?.termMonths || 0}M`,
      `${this.getCommodityRate(c?.supplierRate, c.commodityType)}`,
      (c?.rateType === 'fixed' ? 'Fixed' : 'Variable').replace(/-/g, ' • '),
      swing,
      capitalizeEachFirstLetter(c?.productModelConfiguration?.configuration?.billingMethod),
      `${c?.greenPercent ? `<i class="material-icons large success-2-color">${ZenIconsEnum.GREEN}</i>` : ''}`,
    ].filter(Boolean).join(' • ');

    return details.replace(/,/g, ' • ');
  }

  getCommodityRate(contractRate: number, commodity: CommodityType) {
    return contractRate ? `${this.feeFormat.transform(contractRate, {commodity, currencyType: ZenCurrencyTypeEnum.CURRENCY})}` : '-';
  }

}
