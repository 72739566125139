import {EventEmitter, Injectable} from '@angular/core';
import {
  ZenTableColsModel,
  ZenTableConfig,
  ZenTableMenuOption,
  ZenTooltipHoverModel
} from '../../../../_shared/_components/zen-mat-table/zen-mat-table.component';
import {SelectionModel} from '@angular/cdk/collections';
import {
  ButtonActionTypes,
  ButtonTypes,
  ZenDialogComponent,
  ZenDialogDataModel,
  ZenDialogDataType
} from '../../../../_shared/_dialogs/zen-dialog/zen-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LenContactModel, PfLenDialogRes, PfLensDetailsModel, PfLensTableRowModel} from '../../_model/portfolio-lens.model';
import {PortfolioHelperService} from './portfolio-helper.service';
import {ZenPopperData} from '../../../../_shared/_components/zen-popper/zen-popper.component';
import {isEmptyOrNull, zenFormatAddressType} from '../../../../_shared/_zen-legacy-common/_utils/format-utils';
import {MatTableSize} from '../../../../_shared/_enums/zen-mat-table.enum';
import {ZenDialogMsgService} from '../../../../_shared/_services/zen-dialog-msg.service';
import {ZenDialogPanelClassEnum, ZenDialogSizeEnum} from '../../../../_shared/_enums/zen-dialogs.enum';
import {ZenEditLenDialogComponent} from '../../../../_shared/_dialogs/zen-edit-len-dialog/zen-edit-len-dialog.component';
import {Router} from '@angular/router';
import {
  ActivationObjectType,
  ChangeStatusObjectType,
  DeleteObjectType,
  PortfolioSectionsEnum,
  PortfolioTabsEnum
} from '../../_enums/portfolio-tabs.enum';
import {LenV4Service} from '../../../../_shared/_services/v4/len-v4.service';
import {ZenErrorMsgEnum} from '../../../../_shared/_enums/zen-error-msg.enum';
import {CustomerServiceV4} from '../../../../_shared/_services/v4/customer-v4.service';
import {combineLatest} from 'rxjs';
import {CustomerDetails, PfCustomerDetailsModel} from '../../_model/portfolio-customers.model';
import {ZenBreadcrumbConfig} from '../../../../_shared/_components/zen-breadcrumps/zen-breadcrumb.component';
import {ZenIconsEnum} from '../../../../_shared/_enums/zen-icons.enum';
import {ZenBreadcrumbService} from '../../../../_shared/_services/zen-breadcrumb.service';
import {
  TemporaryStorageService
} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/common/temporary-storage.service';
import {ZenColumnInfoText} from '../../../../_shared/_enums/zen-column-info-text.enum';
import {NgxPopperjsPlacements} from 'ngx-popperjs';
import {PortfolioHierarchyLevelEnum} from '../../_enums/portfolio-hierarchy-level.enum';
import {AuthenticationService} from '../../../../_shared/_zen-legacy-common/zen-common-services/_services/authentication.service';
import {PortfolioFilterService} from './portfolio-filter.service';
import {
  ZenActivationFormDialogComponent
} from '../../../../_shared/_dialogs/zen-activation-form-dialog/zen-activation-form-dialog.component';
import {ActivationChangeBulkModel} from '../../../../_shared/_model/activation-change-bulk.model';
import {ComponentType} from '@angular/cdk/overlay';
import {getActivationStatusFilterValue} from '../../../../_shared/_utils/zen-menu-item.util';
import {ZenCustomerUserHelperService} from '../../../zen-customer-users/_services/zen-customer-user-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ZenLocaleModel} from '../../../../_shared/_model/zen-locale.model';
import {i18nFormatPhoneNumber} from '../../../../_shared/_components/zen-base-with-translate/zen-base-with-translate.component';
import {CommodityType} from '../../../../_shared/_zen-legacy-common/_models/commodity';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MetersV4Service} from '../../../../_shared/_services/v4/meters-v4.service';
import {UnprocurableMeterInfo} from '../../_model/portfolio-meters.model';
import {
  ZenProcurableStatusDialogComponent, ZenProcurementStatusDialogModel
} from '../../../../_shared/_dialogs/zen-procurable-status-dialog/zen-procurable-status-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class PortfolioLensHelperService {

  selectedRows = new SelectionModel<PfLensTableRowModel>(true, []);

  tableMenuOptions: ZenTableMenuOption[] = [
    {
      label: 'Delete', type: 'button', icon: ZenIconsEnum.DELETE, iconPosition: 'left',
      command: () => this.confirmDelete(null, this.selectedRows.selected)
    }, {
      label: 'Active',
      type: 'toggle',
      toggleDefaultVal: true,
      field: 'active',
      command: () => this.handleBulkStatusChange(getActivationStatusFilterValue(this.tableMenuOptions), null, this.selectedRows.selected)
    },
    {
      label: 'Set Procurement Status', type: 'button', icon: ZenIconsEnum.MARK_UNPROCURABLE, iconPosition: 'left',
      command: () =>  this.handleProcurementStatusChangeLen(this.selectedRows.selected)
    }
  ];

  cols: ZenTableColsModel[] = [
    {
      field: 'name', header: 'Legal Entity Name', type: 'standard', enableTooltip: true,
      hyperlink: true, handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewLen(rowData),
      imageCol: 'imageSmallPath', statusCol: 'lenStatusCls', sticky: true, subTextCol: 'customerName',
      headerTooltipText: ZenColumnInfoText.LEN, popperPlacement: NgxPopperjsPlacements.AUTOSTART
    },
    {
      field: 'serviceAddressCount', header: 'Service Addresses', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.SERVICE_ADDRESSES, sortColumnName: 'lenMetrics.serviceAddressCount', hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewLen(rowData, null, PortfolioTabsEnum.SERVICE_ADDRESSES, 0)
    },
    {
      field: 'meterCount', header: 'Meters', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.METERS, sortColumnName: 'lenMetrics.meterCount', hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewLen(rowData, null, PortfolioTabsEnum.METERS, 1)
    },
    {
      field: 'electricityUsage',
      header: 'Usage (kWh)',
      headerColIcon: 'lightbulb',
      align: 'center',
      type: 'standard',
      headerTooltipText: ZenColumnInfoText.ELEC_USAGE,
      sortColumnName: 'lenMetrics.electricityUsage'
    },
    {
      field: 'natGasUsage',
      header: 'Usage',
      headerColIcon: 'whatshot',
      align: 'center',
      type: 'standard',
      headerTooltipText: ZenColumnInfoText.NATGAS_USAGE,
      sortColumnName: 'lenMetrics.natGasUsage'
    },
    {
      field: 'activeRateCheckCountStr',
      header: 'Rate Checks',
      align: 'center',
      type: 'standard',
      headerTooltipText: ZenColumnInfoText.RCS,
      sortColumnName: 'lenMetrics.activeRateCheckCount',
      hyperlink: true,
      handleHyperLinkClick: (col, rowData) => this.pfHelpSvc.handleViewLen(rowData, PortfolioSectionsEnum.RATE_CHECKS, null, null)
    },
    {
      field: 'atRiskPercentage', textClassCol: 'atRiskTextCls', header: 'At Risk', iconCol: 'riskIcon', align: 'center',
      iconClsCol: 'riskIconCls', iconPosition: 'order-last', type: 'standard', enableTooltip: false,
      headerTooltipText: ZenColumnInfoText.AT_RISK,
      sortColumnName: 'lenMetrics.contractMetrics.atRisk.percentage', hideSort: false
    },
    {
      field: 'todayPercentage', header: 'Today', subTextCol: 'todaySubText', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.TODAY,
      sortColumnName: 'lenMetrics.contractMetrics.today.percentage', hideSort: false,
      downloadFormatter: (col, rowData: PfLensTableRowModel) => rowData.todayPercentage !== '-' ? `${rowData.todayPercentage} Contracted` : '-'
    },
    {
      field: 'futurePercentage', header: 'Future', subTextCol: 'futureSubText', align: 'center', type: 'standard',
      headerTooltipText: ZenColumnInfoText.FUTURE,
      sortColumnName: 'lenMetrics.contractMetrics.future.percentage', hideSort: false,
      downloadFormatter: (col, rowData: PfLensTableRowModel) => rowData.futurePercentage !== '-'
        ? `${rowData.futurePercentage} Contracted` : '-'
    },
    {field: 'menu', type: 'menu', stickyEnd: true}
  ];

  tableConfig: ZenTableConfig = {};

  popperData: ZenPopperData[] = []

  /* LEN Details */
  lenDetailsLoaded = new EventEmitter<[PfLensDetailsModel, CustomerDetails]>();
  lenDetails: PfLensDetailsModel;
  loading: boolean;
  translations: ZenLocaleModel;

  constructor(private dialog: MatDialog, private router: Router,
              private lenV4Svc: LenV4Service,
              private custV4Svc: CustomerServiceV4,
              private zenBcSvc: ZenBreadcrumbService,
              private tempStorageSvc: TemporaryStorageService,
              private authSvc: AuthenticationService,
              private pfHelpSvc: PortfolioHelperService,
              private meterV4Svc: MetersV4Service,
              private zenDialogSvc: ZenDialogMsgService,
              private pfHelpFilterSvc: PortfolioFilterService,
              private zenCustomerUserHelper: ZenCustomerUserHelperService,
              private translateSvc: TranslateService
    ) {
    this.translations = Object.values(this.translateSvc.translations)?.[0] as ZenLocaleModel;

    this.tableConfig = {
      styles: {'min-width': MatTableSize.MEDIUM}, // this width is to match the overall top widget width.
      tableMenuOptions: this.tableMenuOptions,
      cols: this.cols,
      searchPlaceholder: 'Search Legal Entity Names',
      download: {
        enable: true,
        getFileNameFn: () => this.pfHelpSvc.getTableDownloadFileName('portfolio_lens')
      },
      onDoubleClick: (rowData) => this.pfHelpSvc.handleViewLen(rowData),
    };
    this.pfHelpFilterSvc.activeStatusUpdate.subscribe(val => {
      this.tableConfig.tableMenuOptions.filter(x => x.label === 'Active').forEach(x => {
        x.toggleDefaultVal = val;
      })
    })
  }

  handleTooltipData({col, element}: ZenTooltipHoverModel) {
    const _data: PfLensTableRowModel = element;
    if (col.field === 'name') {
      this.custV4Svc.getCustomerDetails(element.customerId).subscribe(_customer => {
        const billingAddressIdIsNullAndDefaultIsFalse = _data.billingAddressId == null && !_data.defaultToCustomerAddress;
        const _address =  billingAddressIdIsNullAndDefaultIsFalse ? '-' :  _data.address1 ? zenFormatAddressType(_data) : zenFormatAddressType(_customer);
        this.popperData = [
          {
            title: this.authSvc.customerNameLabel,
            titleIcon: ZenIconsEnum.CUSTOMER,
            value: _data.customerName,
            hideIfBlank: false,
            status: (_data.customerActive ? 'active' : 'inactive'),
            enableCopy: true
          },
          {
            title: 'Legal Entity Name',
            titleIcon: 'account_balance',
            value: _data.name,
            hideIfBlank: false,
            status: (_data.active ? 'active' : 'inactive'),
            enableCopy: true
          },
          {
            title: 'Billing Address',
            value: _address,
            hideIfBlank: false,
            enableCopy: true,
            align: 'center'
          }
        ];
      });
    }
  }

  confirmDelete(rowData?: PfLensTableRowModel, selectedRows?: PfLensTableRowModel[], goBack = false) {
    let ids: string[] = rowData ? [rowData.id] : selectedRows.map(r => r.id);
    const hierarchyDeletable = rowData ? true : this.pfHelpSvc.checkSelectedRowsHierarchy(PortfolioTabsEnum.LENS, selectedRows);

    if (ids.length && hierarchyDeletable) {
      const _customerId = (rowData?.customerId || selectedRows[0]?.customerId);
      this.lenV4Svc.bulkDeleteCheckLen(_customerId, ids).subscribe(deleteCheck => {
        if (deleteCheck.deletable) {
          let dialogData: ZenDialogDataModel = {
            type: ZenDialogDataType.CONFIRM,
            header: {title: 'Portfolio Management'},
            bodyHtml: this.pfHelpSvc.getDeleteBodyText(rowData, selectedRows, DeleteObjectType.LEN,
              rowData?.name, deleteCheck.impactedObjects),
            onClose: () => dialogRef.close(),
            actionButtons: [
              {
                label: 'Confirm', btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
                command: () => {
                  dialogRef.close();
                  this.lenV4Svc.deleteCustomerLenByIds(_customerId, ids).subscribe(() => {
                    this.selectedRows = new SelectionModel<PfLensTableRowModel>(true, []);
                    this.pfHelpSvc.handleAfterDelete(ids, PortfolioTabsEnum.LENS, goBack, _customerId);
                  }, e => {
                    console.log('Error: Delete len by ids ', e);
                    this.zenDialogSvc.openToast(false);
                  });
                }
              }
            ]
          };
          const dialogRef = this.dialog.open(ZenDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData, autoFocus: false
          });
        } else {
          this.zenDialogSvc.openErrorDialog(true, deleteCheck.errorMessage || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        }
      }, e => {
        console.log('ERR: bulk delete len check ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
      });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_DELETE_ERROR);
    }
  }

  /**
   * handleBulkStatusChange - Called when the active toggle on the LENs table or row actions is toggled,
   * deactivates or reactivates the chosen legal entity name(s)
   * @param active - boolean - current Active toggle status
   * @param rowAction - boolean - true for status change from row action toggle, false from table action toggle
   * @param selectedRows - PfLensTableRowModel[] selected rows from the LEN table (null if row action)
   */
  handleBulkStatusChange(active: boolean, rowAction: boolean, selectedRows: PfLensTableRowModel[]) {
    let ids: string[] = selectedRows.map(r => r.id);
    const _customerId = selectedRows.length ? selectedRows[0]?.customerId : null;
    const hierarchySupportsStatusChange = this.pfHelpSvc.checkSelectedRowsHierarchy(PortfolioTabsEnum.LENS, selectedRows);

    if (ids.length && hierarchySupportsStatusChange) {
      this.lenV4Svc.bulkActivationCheckLens(_customerId, ids).subscribe({
        next: activationCheck => {
        if (active || activationCheck.activatable) {
          let dialogData: ZenDialogDataModel = {
            type: ZenDialogDataType.CONFIRM,
            bodyHtml: active ? this.pfHelpSvc.getDeactivateBodyText(rowAction, selectedRows, ChangeStatusObjectType.LEN, false) :
              this.pfHelpSvc.getReactivateBodyText(rowAction, selectedRows, ChangeStatusObjectType.LEN),
            header: {title: 'Portfolio Management'},
            onClose: () => {
              dialogRef.close();
              this.resetTableActionToggle(!rowAction);
            },
            actionButtons: [
              {
                label: 'Confirm', actionType: ButtonActionTypes.SUBMIT, btnType: ButtonTypes.MAT_RAISED_BUTTON, color: 'primary',
                command: (reasonData: Partial<ActivationChangeBulkModel>) => {
                  dialogRef.close();

                  // On Activation -> ZenDialogComponent, On DeActivation -> ZenActivationFormDialogComponent(with reason & notes fields)
                  const _activationBulkChange: ActivationChangeBulkModel =
                    reasonData?.reasonId ? {ids, active: !active, ...reasonData} : {ids, active: !active};

                  // !active is passed to the API since we need to send the desired active/inactive status change, not the current status
                  this.lenV4Svc.bulkChangeStatusLens(_customerId, _activationBulkChange).subscribe( {
                    next: () => {
                      this.selectedRows = new SelectionModel<PfLensTableRowModel>(true, []);
                      this.pfHelpSvc.handleAfterChangeStatus(ids, PortfolioTabsEnum.LENS, _customerId);
                      this.resetTableActionToggle(!rowAction);

                      const _text = active
                        ? this.pfHelpSvc.getDeactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.LEN)
                        : this.pfHelpSvc.getReactivateSuccessBodyText(selectedRows, ChangeStatusObjectType.LEN);

                      active ? this.pfHelpSvc.openSuccessGuidance(_text) : this.pfHelpSvc.openSuccessGuidance(_text);

                    }, error: e => {
                      console.log('ERR: bulk change status LENs ', e);
                      this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
                      this.resetTableActionToggle(!rowAction);
                    }
                  });
                }
              }
            ]
          };

          // On Activation -> ZenDialogComponent, On DeActivation -> ZenActivationFormDialogComponent(with reason & notes fields)
          const _component: ComponentType<ZenActivationFormDialogComponent | ZenDialogComponent>
            = (active ? ZenActivationFormDialogComponent : ZenDialogComponent);

          const dialogRef = this.dialog.open(_component, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData,
            autoFocus: false
          });

        } else {
          this.zenDialogSvc.openErrorDialog(true,
            this.pfHelpSvc.getReactivateErrorBodyText(rowAction, ChangeStatusObjectType.LEN, ActivationObjectType.CUSTOMER));
          this.resetTableActionToggle(!rowAction);
        }

      },
      error: e => {
        console.log('ERR: bulk activation LEN check ', e);
        this.zenDialogSvc.openErrorDialog(true, e.error?.message || ZenErrorMsgEnum.ERR_MSG_1_TEXT);
        this.resetTableActionToggle(!rowAction);
      }
    });
    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.HIERARCHY_STATUS_CHANGE_ERROR);
      this.resetTableActionToggle(!rowAction);
    }
  }

  resetTableActionToggle(tableAction: boolean) {
    if (tableAction) {
      this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal =
        this.tableConfig.tableMenuOptions.find(x => x.label === 'Active').toggleDefaultVal !== true;
    }
  }

  /**
   * Edit len dialog using existing portfolio update serviceAddress services.
   * @param rowData
   * @param lockedCustomerId
   * @param successCallbackFn
   * @param afterClosed
   * @param onlySave
   * @param isOutSidePortfolio - Flag used to know if this edit dialog used inside/outside the portfolio flow.
   */
  handleAddEditLen(rowData?: PfLensTableRowModel, lockedCustomerId?: number, successCallbackFn?: Function,
                   afterClosed?: Function, onlySave = false, isOutSidePortfolio = false) {
    this.selectedRows = new SelectionModel<PfLensTableRowModel>(true, []);
    let dialogData: ZenDialogDataModel = {
      type: lockedCustomerId ? ZenDialogDataType.EDIT_DIALOG : ZenDialogDataType.ADD_DIALOG,
      // lockedCustomerId -> is only used when adding LEN from customer details page, to lock specific customer.
      data: {...rowData, lockedCustomerId, isOutSidePortfolio},
      header: {title: (rowData?.id ? 'Edit' : 'Add') + ' Legal Entity Name', icon: 'settings'},
      bodyText: rowData && rowData.id ? `Edit the information below to maintain an accurate record of this legal entity name.`
        : `Quickly add a legal entity name by providing the required information and any other details you might know.`,
      onClose: () => {
          dialogRef.close();
          if (isOutSidePortfolio) {
            this.zenCustomerUserHelper.init();
          }
        },
      actionButtons: [
        {
          label: 'Cancel',
          actionType: ButtonActionTypes.CANCEL,
          command: (lenData) => {
            console.log('lenData ', lenData)
            dialogRef.close(lenData);
          }
        }
      ]
    };

    /** PM Add/Edit LEN dialog save action
     * 1) Add mode -> i) Save -> Save & goto LEN details page. ii) Save & Close -> stay at current page
     * 2) Edit mode -> Save */
    if (rowData?.id || onlySave) { // Edit mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        command: (lenData: PfLenDialogRes) => {
          dialogRef.close(lenData);
          this.handleSaveAndClose(rowData, lenData, successCallbackFn, isOutSidePortfolio)
        }
      });
    } else { // Add mode
      dialogData.actionButtons.push({
        label: 'Save',
        btnType: ButtonTypes.MAT_RAISED_BUTTON,
        color: 'primary',
        suffixIcon: 'expand_more',
        menuItems: [
          {
            label: 'Save & Close', command: (lenData: PfLenDialogRes) => {
              dialogRef.close(lenData);
              this.handleSaveAndClose(rowData, lenData, successCallbackFn, isOutSidePortfolio)
            }
          },
          {
            label: 'Save & Continue', command: (lenData: PfLenDialogRes) => {
              dialogRef.close();
              this.pfHelpSvc.handleViewLen({...lenData.lenModel} as PfLensTableRowModel);
            }
          }
        ]
      });
    }

    const dialogRef = this.dialog.open(ZenEditLenDialogComponent, {
      width: ZenDialogSizeEnum.LARGE,
      panelClass: ZenDialogPanelClassEnum.MOBILE_FULL_HEIGHT,
      data: dialogData, autoFocus: false
    });

    dialogRef.afterClosed().subscribe(() => {
      if (afterClosed) {
        afterClosed();
      }
    });
  }

  handleSaveAndClose(rowData: PfLensTableRowModel, result: PfLenDialogRes,
                     successCallbackFn?: Function, isOutSidePortfolio = false) {
    if (result?.lenModel) {
      this.pfHelpSvc.refreshCurrentPortfolioPage(result.lenModel.customerId, result.lenModel.id, null);
      if (successCallbackFn) {
        successCallbackFn(result);
      }
      if (this.pfHelpFilterSvc.hierarchyLevel === PortfolioHierarchyLevelEnum.LENS) {
        this.initLenDetails(result.lenModel.customerId, result.lenModel.id);
      }
    } else if (result?.billingAddressUpdated) {
      if (isOutSidePortfolio) {
        this.zenCustomerUserHelper.init();
      } else {
        this.pfHelpSvc.refreshCurrentPortfolioPage(rowData.customerId, rowData.id, null);
      }
    }
  }

  /* LEN Details starts */
  initLenDetails(customerId: number, lenId: string) {
    this.loading = true;
    combineLatest([this.lenV4Svc.getLenDetailsById(customerId, lenId),
      this.custV4Svc.getCustomerDetails(customerId)],
    ).subscribe(([lenDet, custDet]) => {
      this.lenDetails = lenDet;
      this.loading = false;
      this.buildBreadcrumb(lenDet);
      this.lenDetailsLoaded.emit([lenDet, custDet]);
    }, error => {
      console.log(error);
      this.loading = false;
      this.zenDialogSvc.openToast(false);
    });
  }

  getContactLabel(c: LenContactModel) {
    if (c && c.user) {
      let {firstName, lastName, title, email, phone} = c.user;
      return `${firstName} ${lastName}${isEmptyOrNull(title) ?  '' : ', ' + title}${isEmptyOrNull(phone) ?  '' : ', ' + i18nFormatPhoneNumber(phone, this.translations?.id)}${isEmptyOrNull(email) ? '' : email?.length > 20 ? (', ' + email.substring(0, 20) + '...') : ', ' + email}`;
    } else {
      return '-';
    }
  }
  handleProcurementStatusChangeLen(selectedRows: PfLensTableRowModel[]) {
    const uniqueCustomerIds = new Set(selectedRows.map(row => row.customerId));
    const uniqueserviceAddressIds = new Set(selectedRows.map(row => row.id));
    const uniqueStates = [...new Set(selectedRows.map(row => row.state))];
    const uniqueCommodityTypes = [CommodityType.Gas, CommodityType.Electricity];
    let ids: string[] = selectedRows.map(r => r.id);

    if (ids.length && uniqueCustomerIds.size < 2) {
      const finalCustomerId = [...uniqueCustomerIds][0];

          let dialogData: ZenProcurementStatusDialogModel = {
            customerId: finalCustomerId,
            states: uniqueStates,
            commodityTypes: uniqueCommodityTypes,
            lenIds: ids,
            onClose: () => dialogRef.close(),
            onComplete: () => this.pfHelpSvc.handleAfterChangeStatus(),
          };
          const dialogRef = this.dialog.open(ZenProcurableStatusDialogComponent, {
            width: ZenDialogSizeEnum.SMALL,
            data: dialogData,
            autoFocus: false
          });

    } else {
      this.zenDialogSvc.openErrorDialog(true, ZenErrorMsgEnum.CUSTOMER_HIERARCHY_STATUS_CHANGE_ERROR);
    }
  }



  buildBreadcrumb(lenDet: PfLensDetailsModel) {
    const _bcData: ZenBreadcrumbConfig = {
      pageName: lenDet.customerCompanyName,
      breadcrumbs: [
        {icon: ZenIconsEnum.PORTFOLIO, title: 'Portfolio'},
        {icon: ZenIconsEnum.CUSTOMER, title: lenDet.customerCompanyName, customerId: lenDet.customerId},
        {
          icon: ZenIconsEnum.LEN, title: lenDet.name, customerId: lenDet.customerId,
          lenId: lenDet.id, currentPage: true
        }
      ]
    };
    this.zenBcSvc.setConfig(_bcData);
  }

  clearBc() {
    this.zenBcSvc.clear();
  }

  downloadTaxCert(customerId: number, lenDet: PfLensDetailsModel) {
    this.tempStorageSvc.downloadDocument(customerId, lenDet.taxCertificateDocumentId, 'TaxCert__' + lenDet.name)
      .subscribe(() => {
      }, error => {
        console.log('Error: download tax cert ', error);
        this.zenDialogSvc.openToast(false);
      });
  }

  downloadW9(customerId: number, w9DocumentId: number, lenName: string) {
    return this.tempStorageSvc.downloadDocument(customerId, w9DocumentId, 'W-9__' + lenName);
  }

  /* LEN Details ends */

}
